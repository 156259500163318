import Aos from 'aos';
import 'aos/dist/aos.css';
export const Srvcard = (props) => {
    Aos.init({
        duration: 1000
    });
    return (
        <div className="srvhm1" data-aos="fade" data-aos-easing="ease-in-out">
            <div className="srvhm1item1" >
                <h1>
                    {props.title}
                </h1>
                <h5>
                {props.data}
                </h5>
            </div>
            <div className="srvhm1item2">
                <img src={props.imgsrc} alt="" />

            </div>
        </div>
    );
};
export default Srvcard;