import React, { useState } from 'react';
import {Filmsitems} from './Filmsitems';
import { Newfilms } from './Newfilms';
import './work.css';
import Aos from 'aos';
import 'aos/dist/aos.css';

export const Work = () => {
    Aos.init({
        duration: 1000
    });
    const [activework, setActivework] = useState("films");
  

    return (

        <>
            <div className="wrkhm">
                <h1 className="wrkhmtitle" data-aos="fade" data-aos-easing="ease-in-out">STORIES WE'VE<br />TOLD BEFORE</h1>
                <div className="wrkhmnav">
                    <button className='wrkhmnav-link' onClick={() => setActivework("films") } >Films (as Director/Writer)</button>
                    <button className='wrkhmnav-link' onClick={() => setActivework("newfilms")} >Upcoming Films</button>
                    {/* <button className='wrkhmnav-link'>TELE-FILMS</button> */}
                </div>
            </div>
            {
                activework === 'films'
                &&
                <Filmsitems />
            }
            {
                activework === 'newfilms'
                &&
                <Newfilms/>
            }
        </>

    )
};
