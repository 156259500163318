import './App.css';
import React from 'react';
import { Home } from './components/Home/Home';
import { About } from './components/About/About';
import { Contact } from './components/Contact/Contact';
import { Films } from './components/Films/Films';
import { Services } from './components/Services/Services';

import {
    BrowserRouter as Router,
    Route,
    Routes
  } from "react-router-dom";

function App() {
  
  return(
    <>
         
      <Router>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/about" element={<About />} />
          <Route exact path="/films" element={<Films />} />
          <Route exact path="/contactus" element={<Contact />}/>
          <Route exact path="/services" element={<Services />}/>
          {/* <Route exact path="/awards" element={<Awards />}/> */}
          <Route exact path="/insta" component={() => {
            window.location.href='https://www.instagram.com/thinkingbirdproduction';
            return null;
          }} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
