import React from 'react';
import { Link } from "react-router-dom";
import './footer.css'

export const Footer = () => {
    return (
        <>
            <footer className="ftrbase" data-aos="fade">
                <div className="ftritem" >
                    <img src={require("./logo_home.png")} alt="" />
                    <ul className="ftrnav">
                        <li className="ftrnav-link ">
                            <Link className='ftrcnt' to="/contactus"  style={{ textDecoration: 'none' }}>Contact us</Link>
                        </li>
                        <li className="ftrnav-link"><a href='https://www.instagram.com/thinkingbirdproduction/' ><i className='fab fa-instagram'></i></a></li>
                        <li className="ftrnav-link"><a href="https://twitter.com/ThinkingBirdPr?t=YL7kahD13aGrmjfVYFnlIw&s=08" ><i className='fab fa-twitter'></i></a></li>
                        <li className="ftrnav-link"><a href='#' target="_blank"><i className='fab fa-facebook'></i></a></li>
                    </ul>
                </div>
                <div className="ftritem2">
                    <h6>Copyright @ 2022. All Rights Reserved</h6>
                    <div className='developercnt'>
                        Connect to the Developer:  
                        <a href='https://www.instagram.com/inside_world_08/' ><i className='fab fa-instagram'></i></a>
                        <a href='https://www.linkedin.com/in/dhruv-mishra-640b5b199/' ><i className='fa fa-linkedin'></i></a>
                    </div>
                </div>
            </footer>
        </>
    )
}
