import React from 'react'
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
export const Subhomes = () => {
    const settings = {
        fade: true,
        infinite: true,
        autoplay: true,
        speed: 750,
        arrows: false,
        slidesToShow: 1,
        slidesToScroll: 1
    };
    return (
        <>
            <div className="home1">
                <div className="container">
                    <div className="col-lg-6 h1item" data-aos="fade">
                        <h1 className='h1item1'>WHEN OUR STORIES SORE, SO DOES OUR WORLD</h1>
                        <div className="h1item2"></div>
                        <div className="h1item3">
                            <p>Thinking bird production is a film producing company offering concepts
                                that are fully in line with your vision & expectations, the right kind of
                                originality & creativity.</p>

                            <Link style={{ textDecoration: 'none' }} to={"/contactus"}> <button>
                                Let's Connect <i class="fas fa-arrow-alt-circle-right"></i>
                            </button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="home2" >
                <div className="h2item1"></div>
                <h1 className="h2item2">WE</h1>
                <div className="h2item3" data-aos="fade-up">
                    <ul>
                        <li>Believe in appreciating each other and receiving compliments from you.</li>
                        <li>Make promises and we try our best to meet all ends.</li>
                        <li>Are passionate people, your presence adds to our passion.</li>
                        <li>Are result orientated and your appreciation boosts us.</li>

                    </ul>
                    <Link style={{ textDecoration: 'none' }} to={"/about"}><button>
                        Know More About Us  <i class="fas fa-arrow-alt-circle-right"></i>
                    </button></Link>
                </div>
                <div className="h2item4"></div>
            </div>
            <div className="home3">
                <div className="h3item1" data-aos="fade">
                    <Slider {...settings}>
                        <div>
                            <img src={require('./h3img1.png')} alt="..." />
                        </div>
                        <div>
                            <img src={require('./h3img2.png')} alt="..." />
                        </div>
                        <div>
                            <img src={require('./h3img3.png')} alt="..." />
                        </div>
                        <div>
                            <img src={require('./h3img4.png')} alt="..." />
                        </div>
                    </Slider>
                </div>
                <div className="h3item" data-aos="fade">

                    <h1 className="h3item2">
                        ARE YOU STRIVING TO IMAGINE SOMETHING THAT HAS NEVER BEEN DONE BEFORE?</h1>

                    <div className="h3item3">
                        THAT IS SOMETHING WE CAN ASSIST YOU WITH.
                    </div>
                    <div className="h3item4">
                        Filming Services in India includes Location scouting and Preparation of Budget
                        for the all In-House Programme, Managing Production &amp; Technical Department,
                        Managing the logistics of the film shooting equipment's in India, Co-ordination of the
                        vehicles/transport, Facilitating the shooting crew, freelancer technical Crew and
                        filming equipment's if required, Finalization of the payment terms, Controlling
                        the Production during the film shooting, Managing Technical Crew and assigning duties.
                        <Link style={{ textDecoration: 'none' }} to={"/services"}><button>
                            Know More About Us  <i class="fas fa-arrow-alt-circle-right"></i>
                        </button></Link>
                    </div>
                </div>
            </div>
        </>
    )
}
