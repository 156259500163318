import React, { useState } from 'react'
import "./navbar.css"
import { Link } from "react-router-dom";
import { Menuitems } from './Menuitems'
// import { Component } from 'react/cjs/react.development'

export const Header = () => {
    // state = { clicked: false }

    // handleClick = () => {
    //     this.setState({ clicked: !this.state.clicked })
    // }
    const [hamactive, sethamActive] = useState(false);


    return (

        <>
            <nav className='navbritems'>
                {/* <h1 className="navbr_logo">React</h1> */}
                <img className="navbr-logo" src={require('./logo_home.png')} alt="loading..." />
                <div class={hamactive===true ? 'menu btn1 open ' : 'menu btn1 '} data-menu="1" onClick={ () => sethamActive(!hamactive)}>
                    <div class="icon-left"></div>
                    <div class="icon-right"></div>

                </div>
                <ul className={hamactive===true ? 'nav-menu active' : 'nav-menu'}>
                    {
                        Menuitems.map((item, index) => {
                            return (
                                <li key={index}>
                                    <Link className={item.cName} to={item.url}>
                                        {item.title}
                                    </Link >
                                </li>
                            )
                        })
                    }

                </ul>
            </nav>
        </>
    )

}

