import React from 'react'
import {Header} from '../Navbar/Header'
import { Work } from './Work'
import { Footer } from '../Footer/Footer'
export const Films = () => {
  return (
        <>
            <Header/>
            <Work/>
            <Footer/>
        </>
  )
}
