import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import './contact.css';
import {Header} from '../Navbar/Header';
import Aos from 'aos';
import 'aos/dist/aos.css';
import { Footer } from '../Footer/Footer';

export const Contact = () => {
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_e7x3m83', 'template_07kbkl6', form.current, 'h3Hy_eQg1BxMz1Bob')
            .then((result) => {
                console.log("Success");
            }, (error) => {
                console.log(error.text);
            });
            e.target.reset();
    };
    Aos.init({
        duration: 1000,
        once: false, 
        mirror: false
    });
    return (
        <>
            <Header />
            {/* <div className='container'>
                <h1 className='Incenter'>Contact Us</h1>
            </div> */}
            <div className="conhm">
                <div class="conhmitem ">
                    <div className='conhmitem1' >
                        <h1 data-aos="fade" data-aos-easing="ease-in-out">We're here to help and answer any question you might have.</h1>
                        <div className="conhmitem11" data-aos="fade" data-aos-easing="ease-in-out">
                            
                            <img src={require('./conimg2.png')} alt=""/ >
                            <ul className="consocial">
                                {/* <li><i className="fas fa-phone-square-alt"></i></li> */}
                                <li><a href='https://www.instagram.com/thinkingbirdproduction/' ><i className='fab fa-instagram'></i></a></li>
                                <li><a href="https://twitter.com/ThinkingBirdPr?t=YL7kahD13aGrmjfVYFnlIw&s=08"><i className='fab fa-twitter'></i></a></li>
                                <li><i className='fab fa-facebook'></i></li>
                            </ul>
                        </div>
                        
                    </div>
                    <div className='conhmitem2'>
                        <form className="contactform" ref={form} onSubmit={sendEmail} data-aos="fade-up" data-aos-easing="ease-in-out">
                            <h3>
                                Or Directly Send us a <i class="fas fa-comment-alt"></i>
                            </h3>
                            <div className="mb-3">
                                <label htmlFor="Name" className="form-label">Name</label>
                                <input type="text" className="form-control" id="Name" name="Name" required />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="Email" className="form-label">Email address</label>
                                <input type="email" className="form-control" id="Email" name="Email" aria-describedby="emailHelp" required />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="Subject" className="form-label">Subject</label>
                                <input type="text" className="form-control" id="Subject" name="Subject" required />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="Message" className="form-label">Message</label>
                                <textarea type="text" className="form-control" id="Message" name="Message" rows="5" required />
                            </div>

                            <div className='text-center'>
                                <button type="submit" className="btn btn-primary ">Submit</button>
                            </div>

                        </form>
                    </div>
                </div>

            </div>
            <Footer />
        </>

    );
};
