import React from 'react'

export const Filmsitems = () => {
    return (
        <>
            <div className="wrkhmfilms">
                <div className="film" data-aos="fade" data-aos-easing="ease-in-out">
                    <div className="imgtxt">
                        <img src={require('./filmimg1.png')} alt="" className="filmimg" />
                        <div className="filmabt">
                            <p>
                                Hum bhi akele tum bhi akele is a story of two friends,
                                lovers, soulmates and their journey to self-acceptance.
                                Directed by Harish Vyas and produced under the banner
                                of First Ray Films.
                            </p>
                        </div>
                    </div>
                    <div className="filmtxt">
                        <h5>
                            HUM BHI AKELE TUM BHI AKELE (2021)
                        </h5>
                        <hr />
                        
                        <p>
                            Staring : Anshuman Jha , Zareen Khan, gurfateh Pirzada
                            and Jhanvi Rawat. <br/>
                            Produced by Firstray films. 
                        </p>
                        <h6>
                            Streaming On:  Disney+Hotstar
                        </h6>

                    </div>

                </div>
                <div className="film" data-aos="fade" data-aos-easing="ease-in-out">
                    <div className="imgtxt">
                        <img src={require('./filmimg2.png')} alt="" className="filmimg" />
                        <div className="filmabt">
                            <p>
                                A unique tale of romance, with three couples who have very
                                distinct views on what everlasting love is. The film is
                                directed by Harish Vyas under the banner of Drumroll Pictures,
                                Shiny Entertainment and NFDC. It has an exciting ensemble cast
                                which includes  Sanjay Mishra, Ekavali Khanna, Pankaj Tripathi,
                                Ipsita Chokroberty, Anshuman Jha, Brijendra Kala and Shivani
                                Raghuvanshi.

                            </p>
                        </div>
                    </div>
                    <div className="filmtxt">
                        <h5>
                            ANGREZI MEIN KEHTE HAIN ( 2018)
                        </h5>
                        <hr />
                        
                        <p>
                            Starring: Sanjay Mishra , Pankaj Tripathi , Ekavali Khana , Brijender
                            Kala, Anshuman Jha and Shivani Raghuvanshi. <br/>
                            Produced by: Manav Malhotra
                            , Bunty Khan under the banner NFDC , Drumroll Pictures and Shiny
                            entertainment.
                        </p>
                        <h6>
                            Streaming On:  Amazon Prime
                        </h6>


                    </div>

                </div>
                <div className="film" data-aos="fade" data-aos-easing="ease-in-out">
                    <div className="imgtxt">
                        <img src={require('./filmimg3.png')} alt="" className="filmimg" />
                        <div className="filmabt">
                            <p>
                                Proper Patola is a Punjabi feature directed by Harish Vyas.
                                The story revolves around Preet and Jeet who are twin sisters;
                                total opposites. Raj, who marries Preet, decides to take her
                                on a honeymoon while Jeet pretends to be her twin, which
                                creates confusion in the family. The film is made under
                                the banner of Country Media and features Neeru Bajwa,
                                Harish Verma, Yuvraj Hans in the leads.
                            </p>
                        </div>
                    </div>
                    <div className="filmtxt">
                        <h5>
                            PROPER PATOLA (2014)
                        </h5>
                        <hr />
                        
                        <p>
                            Feature film Released in 2014 , Staring Neeru Bajwa, Harish Verma,
                            Yuvraj Hans<br/>
                            Produced by: Depak lalwani
                        </p>
                        <h6>
                            Streaming On:  MX Player
                        </h6>


                    </div>

                </div>
                <div className="film" data-aos="fade" data-aos-easing="ease-in-out">
                    <div className="imgtxt">
                        <img src={require('./filmimg4.jpg')} alt="" className="filmimg" />
                        <div className="filmabt">
                            <p>
                                {/* Hum bhi akele tum bhi akele is a story of two friends,
                                    lovers, soulmates and their journey to self-acceptance.
                                    Directed by Harish Vyas and produced under the banner
                                    of First Ray Films. */}
                            </p>
                        </div>
                    </div>
                    <div className="filmtxt">
                        <h5>
                            ASI DESI (2022)
                        </h5>
                        <hr />
                        <p>
                            Feature film ( Punjabi ) <br/>
                            Produced by: Kranti Pratap Singh under the banner of Kings Films. <br/>
                            Directed by: Harish Vyas. 
                            
                        </p>
                        <a href="https://www.mxplayer.in/movie/watch-asi-desi-movie-online-0b4d1cf7e8111ca2502755582bc112f7" style={{color: "#f4f4f4"}}>
                            <h6>
                                Streaming On:  MX Player
                            </h6>
                        </a>
                        


                    </div>

                </div>

            </div>
        </>
    );
};
