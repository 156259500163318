import React from 'react'
import {Header} from '../Navbar/Header';
import './about.css';
import Aos from 'aos';
import 'aos/dist/aos.css';
import { Footer } from '../Footer/Footer';
export const About = () => {
    Aos.init({
        duration: 1000,
        once: true, 
        mirror: true
    });
    return (
        <>
            <Header />
            <div className="abthm">
                <div className="abthmitem1" data-aos="fade" data-aos-easing="ease-in-out">
                    <img src={require('./abimg2.png')} alt="" />
                </div>
                <div className="abthmitem2" data-aos="fade" data-aos-easing="ease-in-out">
                    <h1>Our Mission is to work on visionary ideas
                        based on both true concepts and fiction that speak
                        directly to the masses through emotions and realities.</h1>
                </div>
            </div>
            <div className="abthm2">
                <div className="abthmitem3" >
                    <h1 data-aos="fade-up" data-aos-easing="ease-in-out">
                        ABOUT US?
                    </h1>
                    <h5 data-aos="fade-up" data-aos-easing="ease-in-out">
                        We believe in offering our best and winning the
                        confidence of our people. We are in the market to
                        offer emotional nourishment to all strata of the
                        society touching exactly where it pains to provide the
                        right ointment. Our Values do recline on personal development
                        but also in providing films that would bring about development
                        in people. We provide values for masses to adopt values. To set an
                        example, we believe in existing and performing. Our Vision is to
                        become irresistible. We believe in net-working, building relationships
                        where people can experience a strong bondage.
                    </h5>
                </div>
                <div className="abthmitem4" >
                    <img src={require('./abimg1.png')} alt="" data-aos="fade-up" data-aos-easing="ease-in-out"/>
                </div>
            </div>

            
                        
            <Footer />
        </>

    );
}
