import React from 'react'
import { Header } from '../Navbar/Header';
import { Work } from '../Films/Work';
import { Footer } from '../Footer/Footer';
import './home.css';
// import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Aos from 'aos';
import 'aos/dist/aos.css';
import { Subhomes } from './Subhomes';
export const Home = () => {
    // const settings = {
    //     fade: true,
    //     infinite: true,
    //     autoplay: true,
    //     speed: 750,
    //     arrows: false,
    //     slidesToShow: 1,
    //     slidesToScroll: 1
    // };
    Aos.init({
        duration: 1000,
        once: false,
        mirror: false,
    });
    return (
        <>
            <Header />
            <Subhomes/>
            <Work />
            <div className="home4">
                <h1 className="h4item1" data-aos="fade">
                    OUR WALL OF <br/> FAME
                </h1>
                <div className="h4item">
                    <div className="h4item11">
                        <img src={require('./h4img11.jpg')} alt="" className="h4item5" data-aos="fade"/>
                        <img src={require('./h4img12.jpg')} alt="" className="h4item5" data-aos="fade"/>
                    </div>
                    <div className="h4item11">
                        <img src={require('./h4img2.jpg')} alt="" className="h4item3" data-aos="fade"/>
                        <img src={require('./h4img5.jpg')} alt="" className="h4item4" data-aos="fade"/>
                        <img src={require('./h4img13.jpg')} alt="" className="h4item5" data-aos="fade"/>
                    </div> 
                     
                    <div className="h4item11">
                        <img src={require('./h4img4.jpg')} alt="" className="h4item3" data-aos="fade"/>
                        <img src={require('./h4img10.jpg')} alt="" className="h4item4" data-aos="fade"/>
                        <img src={require('./h4img1.jpg')} alt="" className="h4item3" data-aos="fade"/>
                        <img src={require('./h4img7.jpg')} alt="" className="h4item4" data-aos="fade"/>
                       
                    </div> 
                    
                    
                    <div className="h4item11">
                        <img src={require('./h4img3.jpg')} alt="" className="h4item5" data-aos="fade"/>
                        <img src={require('./h4img9.jpg')} alt="" className="h4item5" data-aos="fade"/>
                    </div> 
                </div>
            </div>
            <Footer/>
        </>

    );
};

