import React, { useState } from 'react'
import {Header} from '../Navbar/Header';
import Srvcard from './srvcard';
import './services.css';
import { Footer } from '../Footer/Footer';
export const Services = () => {
    const [active, setActive] = useState("Firstcard");
    

    return (
        <>
            <Header />
            <div className="srvnav">
                <button classname="srvnav-link" onClick={ () => setActive("Firstcard")} autoFocus>Support</button>
                <button classname="srvnav-link" onClick={ () => setActive("Secondcard")}>Locations</button>
                <button classname="srvnav-link" onClick={ () => setActive("Thirdcard")}>Permissions</button>
            </div> 
            {
                active === "Firstcard" 
                &&
                <Srvcard
                
                title="CREW AND TECHNICIANS"
                data="We have a data bank of Experienced Film Crews who have experience of
                working with foreign film makers. And they can be arranged instantly."
                imgsrc={require('./srvimg1.png')} />
            }
            

            {
                active === "Secondcard" 
                &&
                <Srvcard 
                data-aos-easing="ease-in-out"
                title="LOCATION SCOUTING"
                data="Locations suitable to your script and storyboard, we forward
                you the photographs/video clips of the location which gives you a right idea."
                imgsrc={require('./srvimg2.png')} />
            }
            
            {
                active === "Thirdcard"
                &&
                <Srvcard 

                data-aos-easing="ease-in-out"
                title="VARIOUS GOVT. / PRIVATE PERMISSIONS"
                data="Ministry of Railways for shooting at Railway stations, Ministry of
                Civil aviation for film shooing at Airports of India, and Archaeological Survey of India for
                shooting at the monuments etc.,  All private premise"
                imgsrc={require('./srvimg3.png')} />
            }


            


            
            <Footer/>
        </>

    )
}
