import React from 'react'

export const Newfilms = () => {
    return (
        <>
            <div className="wrkhmfilms">
                <div className="film" data-aos="fade" data-aos-easing="ease-in-out">
                    <div className="imgtxt">
                        <img src={require('./newfilmimg1.png')} alt="" className="filmimg" />
                        <div className="filmabt">
                            <p>
                                Jugnu, a cab driver with a dark past lives a reclusive life 
                                withdrawn from the city hustle and bustle and the people 
                                around him. Living in the shadow of a gloomy past he finds 
                                solace in the company of a mannequin. As lonely as his 
                                existence as silent as his demeanor. She is the centre of 
                                his life, a relation beyond words and definitions. 
                            </p>
                        </div>
                    </div>
                    <div className="filmtxt">
                        <h5>
                            MAJNUN JUGNU (2021)
                        </h5>
                        <hr />
                        <p>
                            Starring: Anurag Malhan, Tara Alisha Berry<br/>  
                            Produced by: Akion entertainment & Thinking bird Production
                        </p>


                    </div>

                </div>
                <div className="film" data-aos="fade" data-aos-easing="ease-in-out">
                    {/* <div className="imgtxt">
                        <img src={require('./filmimg2.png')} alt="" className="filmimg" />
                        <div className="filmabt">
                            <p>
                                In the hectic chores of modern lifestyle, a vacation rejuvenates 
                            mind, body and soul. Sometimes all that we need is just a little
                             break from the daily routine of the mundane life.
                            Most families go out on trips at least once a year but this 
                            story is about a family who have never gone on a vacation. 
                            Ever. Hailing from Mathura - The land of Shri Krishna, 
                            'TRIPATHIS' go on their first ever family vacation and it sure is 
                            a rollercoaster ride. 

                            </p>
                        </div>
                    </div> */}
                    <div className="filmtxt">
                        <h5>
                            YAATRIS
                        </h5>
                        <hr />
                        <h6>
                            In the hectic chores of modern lifestyle, a vacation rejuvenates 
                            mind, body and soul. Sometimes all that we need is just a little
                             break from the daily routine of the mundane life.
                            Most families go out on trips at least once a year but this 
                            story is about a family who have never gone on a vacation. 
                            Ever. Hailing from Mathura - The land of Shri Krishna, 
                            'TRIPATHIS' go on their first ever family vacation and it sure is 
                            a rollercoaster ride.       
                        </h6>
                        <p>
                            Starring: Raghubir Yadav, Seema Pahwa, Jami Lever, Anurag Malhan<br/>
                            Produced by: Akion entertainment & Thinking bird Production
                        </p>
                    </div>

                </div>
            </div>
        </>
    )
}
