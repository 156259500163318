export const Menuitems = [
    {
        title: 'Home',
        url: '/',
        cName: 'nav-links'
    },
    {
        title: 'About',
        url: '/about',
        cName: 'nav-links'
    },
    {
        title: 'Services',
        url: '/services',
        cName: 'nav-links'
    },
    {
        title: 'Film Gallary',
        url: '/films',
        cName: 'nav-links'
    },
    {
        title: 'Contact',
        url: '/contactus',
        cName: 'nav-links'
    },
    
]